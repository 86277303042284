<template>
    <div :class="['theme-switcher', { active: isActive }]">
        <div class="toggle-theme-switcher" @click="toggleActive">
            <i class="las la-cog"></i>
        </div>
        <div class="theme-switcher-inner custom-scrollbar">
            <div class="panel-group">
                <div class="panel-title">
                    <h6 class="title">Color Skin</h6>
                </div>
                <ul class="color-skin list-inline">
                    <li
                        v-for="(color, index) in colors"
                        :key="color.name"
                        :class="[
                            'color-item',
                            color.name,
                            { active: activeColor === index },
                        ]"
                        :data-color="color.color"
                        @click="setActiveColor(index)"
                    ></li>
                </ul>
            </div>

            <div class="panel-group">
                <div class="panel-title">
                    <h6 class="title">Direction</h6>
                </div>
                <div class="direction-buttons">
                    <a
                        href="#"
                        @click.prevent="changeDirection('en')"
                        :class="[
                            'btn',
                            'btn-ltr',
                            {
                                'btn-primary': currentLanguage === 'en',
                                'btn-default': currentLanguage !== 'en',
                            },
                        ]"
                        >LTR</a
                    >
                    <a
                        href="#"
                        @click.prevent="changeDirection('fa')"
                        :class="[
                            'btn',
                            'btn-rtl',
                            {
                                'btn-primary': currentLanguage === 'fa',
                                'btn-default': currentLanguage !== 'fa',
                            },
                        ]"
                        >RTL</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tinycolor from "tinycolor2";

export default {
    data() {
        return {
            isActive: false,
            activeColor: 0,
            currentLanguage: "en",
            colors: [
                { name: "blue", color: "#0068e1" },
                { name: "bondi-blue", color: "#0095b6" },
                { name: "cornflower", color: "#6453f7" },
                { name: "violet", color: "#723881" },
                { name: "red", color: "#f51e46" },
                { name: "yellow", color: "#fa9928" },
                { name: "orange", color: "#fd6602" },
                { name: "green", color: "#59b210" },
                { name: "pink", color: "#ff749f" },
                { name: "black", color: "#2a3447" },
                { name: "indigo", color: "#4b0082" },
                { name: "magenta", color: "#f8008c" },
            ],
        };
    },
    created() {
        this.currentLanguage = this.getCurrentLanguage();
    },
    methods: {
        toggleActive() {
            this.isActive = !this.isActive;
        },
        setActiveColor(index) {
            const baseColor = this.colors[index].color;

            const primaryHover = tinycolor(baseColor).darken(8).toString();
            const primaryDark = tinycolor(baseColor).darken(50).toString();
            const primaryTransparent = tinycolor(baseColor)
                .setAlpha(0.8)
                .toRgbString();
            const primaryTransparentLite = tinycolor(baseColor)
                .setAlpha(0.3)
                .toRgbString();
            const primaryTransparentLite2 = tinycolor("#fd6602")
                .setAlpha(0.12)
                .toRgbString();

            $("body").attr(
                "style",
                `

        --color-primary: ${baseColor};
        --color-primary-hover: ${primaryHover};
        --color-primary-transparent: ${primaryTransparent};
        --color-primary-transparent-lite: ${primaryTransparentLite};

            `
            );

            this.activeColor = index;
        },
        getCurrentLanguage() {
            const currentUrl = window.location.href;
            const urlParts = currentUrl.split("/");
            return urlParts[3] || "en";
        },
        changeDirection(language) {
            const currentUrl = window.location.href;
            const urlParts = currentUrl.split("/");
            if (urlParts.length > 3) {
                urlParts[3] = language;
                const newUrl = urlParts.join("/");
                window.location.href = newUrl;
            }
        },
    },
};
</script>

<style scoped>
.theme-switcher {
    position: fixed;
    top: 120px;
    width: 220px;
    background: #ffffff;
    box-shadow: none;
    z-index: 80;
    transition: 0.3s ease-in-out;
}

.ltr .theme-switcher {
    left: -220px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rtl .theme-switcher {
    right: -220px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.theme-switcher.active {
    box-shadow: 0 3px 6px #00000026;
}

.ltr .theme-switcher.active {
    left: 0;
}

.rtl .theme-switcher.active {
    right: 0;
}

.theme-switcher .toggle-theme-switcher {
    position: absolute;
    font-size: 24px;
    top: 30px;
    display: flex;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-content: center;
    color: #0068e1;
    color: var(--color-primary);
    background: #ffffff;
    box-shadow: 0 3px 6px #00000026;
    cursor: pointer;
    z-index: -1;
}

.ltr .theme-switcher .toggle-theme-switcher {
    right: -40px;
    border-radius: 0 50% 50% 0;
}

.rtl .theme-switcher .toggle-theme-switcher {
    left: -40px;
    border-radius: 50% 0 0 50%;
}

.ltr .theme-switcher .toggle-theme-switcher i {
    animation: spin-infinity-ltr 12s linear infinite;
}

.rtl .theme-switcher .toggle-theme-switcher i {
    animation: spin-infinity-rtl 12s linear infinite;
}

.theme-switcher-inner {
    position: relative;
    padding: 20px 0 25px;
    background: #ffffff;
}

.ltr .theme-switcher-inner {
    border-radius: 0 8px 8px 0;
}

.rtl .theme-switcher-inner {
    border-radius: 8px 0 0 8px;
}

.theme-switcher-inner .panel-group {
    padding: 0 20px;
}

.theme-switcher-inner .panel-title {
    position: relative;
    margin-bottom: 12px;
    z-index: 0;
}

.theme-switcher-inner .panel-title:after {
    position: absolute;
    content: "";
    top: 10px;
    height: 1px;
    width: 100%;
    background: #ebebeb;
    z-index: -1;
}

.ltr .theme-switcher-inner .panel-title:after {
    left: 0;
}

.rtl .theme-switcher-inner .panel-title:after {
    right: 0;
}

.theme-switcher-inner .title {
    display: inline-block;
    background: #ffffff;
}

.ltr .theme-switcher-inner .title {
    padding-right: 10px;
}

.rtl .theme-switcher-inner .title {
    padding-left: 10px;
}

.theme-switcher-inner .color-skin {
    display: flex;
    flex-wrap: wrap;
    margin: -7px -7px 13px;
}

.theme-switcher-inner .color-item {
    display: inline-block;
    position: relative;
    flex: 1 0 calc(25% - 14px);
    margin: 7px;
    border-radius: 5px;
    cursor: pointer;
}

.theme-switcher-inner .color-item.active:after {
    opacity: 1;
    visibility: visible;
}

.theme-switcher-inner .color-item:before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.theme-switcher-inner .color-item:after {
    position: absolute;
    content: "";
    top: calc(50% - 5px);
    height: 7px;
    width: 12px;
    border: 2px solid #ffffff;
    border-top: none;
    border-right: none;
    opacity: 0;
    visibility: hidden;
}

.ltr .theme-switcher-inner .color-item:after {
    left: 50%;
    transform: translate(-50%) rotate(-45deg);
}

.rtl .theme-switcher-inner .color-item:after {
    left: 50%;
    transform: translate(-50%) rotate(-45deg) rotateY(180deg);
}

.theme-switcher-inner .color-item.blue {
    background: #0068e1;
}

.theme-switcher-inner .color-item.bondi-blue {
    background: #0095b6;
}

.theme-switcher-inner .color-item.cornflower {
    background: #6453f7;
}

.theme-switcher-inner .color-item.violet {
    background: #723881;
}

.theme-switcher-inner .color-item.red {
    background: #f51e46;
}

.theme-switcher-inner .color-item.yellow {
    background: #fa9928;
}

.theme-switcher-inner .color-item.orange {
    background: #fd6602;
}

.theme-switcher-inner .color-item.green {
    background: #59b210;
}

.theme-switcher-inner .color-item.pink {
    background: #ff749f;
}

.theme-switcher-inner .color-item.black {
    background: #2a3447;
}

.theme-switcher-inner .color-item.indigo {
    background: #4b0082;
}

.theme-switcher-inner .color-item.magenta {
    background: #f8008c;
}

.theme-switcher-inner .custom-color-picker {
    margin-bottom: 20px;
}

.theme-switcher-inner .custom-color-picker .clr-field button {
    height: 32px;
    border-radius: 5px;
}

.ltr .theme-switcher-inner .custom-color-picker .clr-field button {
    right: 4px;
}

.rtl .theme-switcher-inner .custom-color-picker .clr-field button {
    left: 4px;
}

.theme-switcher-inner .custom-color-picker .clr-field button:after {
    box-shadow: none;
}

.theme-switcher-inner .custom-color-picker .form-control {
    height: 40px;
}

.theme-switcher-inner .direction-buttons {
    display: flex;
}

.theme-switcher-inner .direction-buttons .btn-ltr {
    flex-grow: 1;
}

.ltr .theme-switcher-inner .direction-buttons .btn-ltr {
    margin-right: 5px;
}

.rtl .theme-switcher-inner .direction-buttons .btn-ltr {
    margin-left: 5px;
}

.theme-switcher-inner .direction-buttons .btn-rtl {
    flex-grow: 1;
}

.ltr .theme-switcher-inner .direction-buttons .btn-rtl {
    margin-left: 5px;
}

.rtl .theme-switcher-inner .direction-buttons .btn-rtl {
    margin-right: 5px;
}

@media screen and (min-height: 565px) {
    .theme-switcher-inner.custom-scrollbar {
        overflow: visible;
    }
}

@media screen and (max-height: 655px) {
    .theme-switcher {
        top: 0;
        bottom: 0;
        height: 100%;
    }

    .ltr .theme-switcher {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rtl .theme-switcher {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .theme-switcher.active {
        box-shadow: 0 0 6px #00000026;
    }

    .theme-switcher .toggle-theme-switcher {
        top: 110px;
    }

    .theme-switcher-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    .ltr .theme-switcher-inner {
        left: 0;
    }

    .rtl .theme-switcher-inner {
        right: 0;
    }
}

@keyframes spin-infinity-ltr {
    0% {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-infinity-rtl {
    0% {
        transform: rotate(0);
    }

    to {
        transform: rotate(-360deg);
    }
}

.fc-purchase-theme {
    position: fixed;
    bottom: 20px;
    background: #82b440;
    border-radius: 50%;
    box-shadow: 0 3px 4px #0000001a;
    z-index: 70;
}

.ltr .fc-purchase-theme {
    left: 20px;
}

.rtl .fc-purchase-theme {
    right: 20px;
}

.fc-purchase-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 109px;
    height: 58px;
    border-radius: 72px;
    background: #82b440;
    padding: 5px;
}

.fc-purchase-link .buy-now {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-transform: capitalize;
    word-break: break-all;
    width: 55px;
}

.ltr .fc-purchase-link .buy-now {
    padding-left: 6px;
    padding-right: 16px;
}

.rtl .fc-purchase-link .buy-now {
    padding-right: 6px;
    padding-left: 16px;
}

.fc-purchase-link .fc-purchase-price {
    width: 48px;
    height: 48px;
    background: #9bc366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20.912px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -1.307px;
    text-transform: capitalize;
}

.fc-purchase-link .fc-purchase-price > span {
    color: #fff;
    font-size: 10.456px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
    position: relative;
    top: -3px;
}

.ltr .fc-purchase-link .fc-purchase-price > span {
    left: -3px;
}

.rtl .fc-purchase-link .fc-purchase-price > span {
    right: -3px;
}

@keyframes flip-ltr {
    0% {
        transform: rotateY(0);
    }

    80% {
        transform: rotateY(0);
    }

    to {
        transform: rotateY(360deg);
    }
}

@keyframes flip-rtl {
    0% {
        transform: rotateY(0);
    }

    80% {
        transform: rotateY(0);
    }

    to {
        transform: rotateY(-360deg);
    }
}

@media screen and (max-width: 991px) {
    .fc-purchase-theme {
        bottom: 85px;
    }

    .ltr .fc-purchase-theme {
        left: 15px;
    }

    .rtl .fc-purchase-theme {
        right: 15px;
    }

    .ltr .fc-purchase-link {
        width: 60px;
        min-width: 60px;
        height: 60px;
        flex-direction: column;
        justify-content: center;
    }

    .ltr .fc-purchase-link .fc-purchase-price {
        width: unset;
        height: unset;
        background: transparent;
    }

    .ltr .fc-purchase-link .buy-now {
        font-size: 10px;
        font-weight: 300;
        word-break: unset;
        width: auto;
        text-align: center;
        padding-right: 4px;
        padding-left: 4px;
    }
}
</style>
